import React, { useState, useContext } from 'react';
import styles from './ContactForm.module.css';
import ThemeContext from '../../contexts/ThemeContext';

const initialFormState = {
  name: '',
  contact: '',
  email: '',
  message: '',
};

const FormInput = ({ value, name, type, placeholder, onChange, pattern, maxLength, hasError }) => {
  const { testimonialColors } = useContext(ThemeContext);
  return (
    <div
      className={styles.inputContainer}
      style={{ 
        borderColor: hasError ? 'red' : testimonialColors.cardImageBorderColor 
      }}
    >
      <input
        data-testid={name}
        required
        type={type}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        pattern={pattern}
        maxLength={maxLength}
      />
    </div>
  );
};

const ContactForm = () => {
  const {
    secondaryFont,
    secondaryColor,
    blackColor,
    whiteColor,
    testimonialColors,
  } = useContext(ThemeContext);

  const [formData, setFormData] = useState(initialFormState);
  const [errors, setErrors] = useState({});
  const { name, contact, email, message } = formData;

  const validateInput = (name, value) => {
    switch (name) {
      case 'name':
        if (!/^[a-zA-Z\s]{2,30}$/.test(value)) {
          return 'Name should only contain letters and spaces (2-30 characters)';
        }
        break;
      case 'contact':
        if (!/^\+?[\d\s-]{10,15}$/.test(value)) {
          return 'Please enter a valid phone number (10-15 digits)';
        }
        break;
      case 'message':
        if (!value.trim()) {
          return 'Message cannot be empty';
        }
        break;
      default:
        return '';
    }
    return '';
  };

  const handleValueChange = (event) => {
    const { name, value } = event.target;
    const error = validateInput(name, value);
    
    setErrors({
      ...errors,
      [name]: error
    });

    setFormData({ ...formData, [name]: value });
  };

  const secondaryFontStyle = {
    fontFamily: secondaryFont,
    color: blackColor,
  };
  const buttonStyle = {
    color: whiteColor,
    backgroundColor: secondaryColor,
  };

  return (
    <div className={styles.contactForm} data-testid="Contact-From">
      <form
        name="contact-form"
        method="post"
        data-testid="ContactFormElement"
        style={secondaryFontStyle}
      >
        <input type="hidden" name="form-name" value="contact-form" />
        <div className={styles.formInputs}>
          <FormInput
            label="Name"
            value={name}
            name={'name'}
            type="text"
            placeholder="Name"
            onChange={handleValueChange}
            pattern="[a-zA-Z\s]{2,30}"
            maxLength={30}
            hasError={!!errors.name}
          />
          <FormInput
            label="Phone"
            value={contact}
            name={'contact'}
            type="tel"
            placeholder="Phone"
            onChange={handleValueChange}
            pattern="[\d\s-]{10,15}"
            maxLength={15}
            hasError={!!errors.contact}
          />
        </div>
        <div className={styles.emailInput}>
          <div className={styles.formInputs}>
            <FormInput
              label="Email"
              value={email}
              name={'email'}
              type="email"
              placeholder="Email"
              onChange={handleValueChange}
            />
          </div>
        </div>
        <div className={styles.formInputs}>
          <div
            className={styles.inputTextArea}
            style={{ 
              borderColor: errors.message ? 'red' : testimonialColors.cardImageBorderColor 
            }}
          >
            <textarea
              data-testid="message"
              required
              style={secondaryFontStyle}
              name="message"
              placeholder="How can we help you?"
              rows={10}
              value={message}
              onChange={handleValueChange}
            />
            <button 
              type="submit" 
              style={buttonStyle}
              disabled={Object.keys(errors).some(key => errors[key])}
            >
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ContactForm;
